
import { ref } from "vue"
import ResultSet from "@/models/ResultSet"
import ReleasesService from "@/services/ReleasesService"

export default {
  setup() {
    const service = new ReleasesService()
    const collectionComponent = ref()

    function loadPart(part: Number, sort: any) {
      service.getHalapiVersions(part, sort).then((result: ResultSet<any>) => {
        if (collectionComponent.value) collectionComponent.value.onPartLoaded(part, sort, result)
      })
    }

    const sortOptions = ref([
      { label: "Release Date ↓", value: "createdTime,asc" },
      { label: "Release Date ↑", value: "createdTime,desc" },
    ])

    function onVersionChanged(version: any, notes: any) {
      service.updateHalapiVersion(version, notes).then(() => {
        collectionComponent.value.reload()
      })
    }
    return { collectionComponent, loadPart, onVersionChanged, sortOptions }
  },
  components: {},
}
